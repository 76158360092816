<template>
    <div class="box">
        <h2>Prüfungsliste erstellen</h2>
        <div class="body">
            <form @submit.prevent="save()">
                <div class="form-group form-float">
                    <input v-model="checklist.label" v-validate="'required'" id="label" name="label" placeholder=" " type="text" />
                    <label for="title">Bezeichner</label>
                    <span class="error" v-show="errors.has('label')">{{ errors.first("label") }}</span>
                </div>

                <div>
                    <dl>
                        <dt>Type:</dt>
                        <dd v-show="type == 'list'">
                            Crefo Listenbasiert: <b>{{ listid }}</b>
                        </dd>
                        <dd v-show="type == 'freetext'">Freitext</dd>
                    </dl>
                </div>

                <hr />

                <div class="button-row">
                    <button type="submit" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            type: "",
            listid: "",
            checklist: {
                label: ""
            }
        };
    },
    methods: {
        save() {
            this.$validator.validateAll().then(valid => {
                if (!valid) {
                    return;
                }

                let data = {
                    label: this.checklist.label,
                    type: this.type,
                    keylist: this.listid
                };

                this.$api.post("/crefo/checklist", data).then(
                    response => {
                        // Checklist created
                        this.$snotify.success("Checklist created");
                        this.$router.push({ name: "crefo_checklist_edit", params: { id: response.data.data.id } });
                    },
                    () => {
                        // Create failed
                        this.$snotify.error("Create Checklist failed");
                    }
                );
            });
        }
    },
    created() {
        if (this.$route.name === "crefo_checklist_createfreetext") {
            this.type = "freetext";
        } else {
            this.type = "list";
            this.listid = this.$route.params.id;
        }
    }
};
</script>
